// очистка параметров авторизации
const clearPath = window.location.pathname + window.location.hash;
window.history.replaceState({}, null, clearPath);

//import './core-tcell';
import karaoke from '../../application/app-karaoke';
import brandingRunner from './app-tcell';

const opRes = require.context('../../application/karaoke/', true, /tcell\.m\.less/);
brandingRunner(karaoke, opRes);
